<template>
  <div>
    <b-alert v-model="isDraftAvailable" v-height-fade.appear variant="warning">
      <div class="alert-body">
        <b-row>
          <b-col class="align-self-center">
            Do you want to restore the draft data?
          </b-col>
          <b-col class="align-self-center text-right">
            <b-button @click="removeDraft" variant="outline-danger" class="mr-2"
              >Ignore</b-button
            >
            <b-button @click="restoreDraft" variant="primary">Restore</b-button>
          </b-col>
        </b-row>
      </div>
    </b-alert>
    
    <b-tabs>
      <b-tab active title="English">
        <b-card title="Add Gallery Item">
          <validation-observer ref="galleryItemForm">
            <b-form>
              <b-row>
                <b-col cols="6">
                  <b-form-group label="Title" class="mb-0">
                    <validation-provider
                      #default="{ errors }"
                      name="Title"
                      rules="required"
                    >
                      <b-form-input
                        v-model="gallery.title"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Title"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Media Type">
                    <validation-provider
                      #default="{ errors }"
                      name="Type"
                      rules="required"
                    >
                      <v-select
                        v-model="gallery.type"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Type"
                        :options="['image', 'video']"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- <b-col md="4">
              <b-form-group label="Location">
                  <b-form-input
                    v-model="gallery.location"
                    placeholder="Location"
                  />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Media Event">
              <b-row>
                <b-col cols="2">
                  <b-form-checkbox
                            checked="true"
                            v-model="hasNoDate"
                            name="check-button"
                            class="mb-1 mt-1"
                            switch
                            inline
                          >Disable Date</b-form-checkbox>
                </b-col>
              </b-row>
               <b-row v-if="!hasNoDate">
                   <b-col cols="6">
                <b-calendar
                  v-model="gallery.eventDate.startDate"
                  block
                  locale="en-US"
                />
              </b-col>
                 <b-col cols="6">
                    <b-calendar
                  v-model="gallery.eventDate.endDate"
                  block
                  locale="en-US"
                />
                 </b-col>
                </b-row>
              </b-form-group>
            </b-col> -->
              </b-row>
            </b-form>
          </validation-observer>
        </b-card>
        <b-card title="Media Content">
          <b-tabs>
            <b-tab active title="Video Type">
              <b-form>
                <b-row>
                  <b-col vols="6">
                    <b-form-group label="Banner Video">
                      <b-form-input
                        v-model="gallery.bannerVideo"
                        placeholder="Banner URL"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col vols="6">
                    <b-form-group label="Thumbnail Video">
                      <b-form-input
                        v-model="gallery.thumbnailVideo"
                        placeholder="Thumbnail Url"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-col cols="12">
                      <b-form
                        class="repeater-form"
                        @submit.prevent="repetVideoGallery(null)"
                      >
                        <b-row
                          v-for="(galleryItem, index) in gallery.videoGallery"
                          :key="index"
                          ref="childGalleryItemRow"
                        >
                          <b-col cols="12">
                            <b-form-group
                              style="background: white"
                              :label="'Gallery Url ' + (index + 1)"
                            >
                              <b-row>
                                <b-col cols="5">
                                  <b-form-input
                                    v-model="gallery.videoGallery[index]"
                                    placeholder="URL"
                                  />
                                </b-col>
                                <b-col lg="2" md="3" class="mb-50">
                                  <b-button
                                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                                    variant="outline-danger"
                                    class="mt-0 mt-md-2"
                                    @click="removeVideoGallery(null, index)"
                                  >
                                    <feather-icon icon="XIcon" class="mr-25" />
                                    <span>Remove</span>
                                  </b-button>
                                </b-col>
                              </b-row>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col class="mt-1"
                            ><b-button
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="primary"
                              @click="repetVideoGallery(null)"
                            >
                              <feather-icon icon="PlusIcon" class="mr-25" />
                              <span>{{
                                gallery.videoGallery.length === 0
                                  ? "Add Video URL"
                                  : "Add New URL"
                              }}</span>
                            </b-button></b-col
                          >
                        </b-row>
                      </b-form>
                    </b-col>
                  </b-col>
                  <b-col cols="12">
                    <hr />
                  </b-col>
                </b-row>
              </b-form>
            </b-tab>
            <b-tab title="Image Type">
              <b-form>
                <b-row>
                  <b-col vols="6">
                    <b-form-group label="Banner Image">
                      <b-img
                        class="mb-1"
                        v-bind="mainProps"
                        v-if="gallery.bannerImg"
                        :src="gallery.bannerImg"
                      ></b-img>
                      <b-button
                        v-if="
                          gallery.bannerImg ? gallery.bannerImg.length > 0 : ''
                        "
                        size="sm"
                        @click="gallery.bannerImg = ''"
                        variant="gradient-danger"
                        class="btn-icon ml-2"
                      >
                        <feather-icon icon="TrashIcon" />
                      </b-button>
                      <ComponentMediaSelector
                      :params="{lang: null}"
                        :isMultiple="false"
                        @mediaSelected="handlebannerImgSelect"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col vols="6">
                    <b-form-group label="Thumbnail Image">
                      <b-img
                        class="mb-1"
                        v-bind="mainProps"
                        v-if="gallery.thumbnailImg"
                        :src="gallery.thumbnailImg"
                      ></b-img>
                      <b-button
                        v-if="
                          gallery.thumbnailImg
                            ? gallery.thumbnailImg.length > 0
                            : ''
                        "
                        size="sm"
                        @click="gallery.thumbnailImg = ''"
                        variant="gradient-danger"
                        class="btn-icon ml-2"
                      >
                        <feather-icon icon="TrashIcon" />
                      </b-button>
                      <ComponentMediaSelector
                      :params="{lang: null}"
                        :isMultiple="false"
                        @mediaSelected="handlethumbnailImgSelect"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group label="Image Gallery">
                      <div
                        class="mb-1 hotel-gallery"
                        v-if="
                          gallery.imageGallery
                            ? gallery.imageGallery.length > 0
                            : ''
                        "
                      >
                        <div
                          class="gallery-item"
                          :class="index == 0 ? 'mr-1 ml-0' : 'mx-1'"
                          v-for="(media, index) in gallery.imageGallery"
                          :key="media.key"
                        >
                          <div class="actions">
                            <b-button
                              size="sm"
                              @click="removeImageGallery(null, index)"
                              variant="gradient-danger"
                              class="btn-icon"
                            >
                              <feather-icon icon="TrashIcon" />
                            </b-button>
                          </div>
                          <b-img
                            v-bind="mainProps"
                            rounded
                            :src="media.src"
                          ></b-img>
                        </div>
                      </div>
                      <ComponentMediaSelector
                      :params="{lang: null}"
                        :selectedMediaFiles="gallery.imageGallery"
                        @mediaSelected="handleImageGallery"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form>
            </b-tab>
          </b-tabs>
        </b-card>
        <b-card title="Button Content">
          <b-form>
            <b-row>
              <b-col cols="6">
                <b-form-group label="Button Title">
                  <b-form-input
                    v-model="gallery.button.buttonText"
                    placeholder="Button Title"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Button Link">
                  <b-form-input
                    v-model="gallery.button.buttonUrl"
                    placeholder="Button Link"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </b-tab>

      <b-tab title="Hindi">
        <b-card title="Add Gallery Item">
            <b-form>
              <b-row>
                <b-col cols="6">
                  <b-form-group label="Title" class="mb-0">
                      <b-form-input
                        v-model="gallery.translations.hi.title"
                        placeholder="Title"
                      />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Media Type">
                      <v-select
                        v-model="gallery.translations.hi.type"
                        placeholder="Type"
                        :options="['image', 'video']"
                      />
                  </b-form-group>
                </b-col>
                <!-- <b-col md="4">
              <b-form-group label="Location">
                  <b-form-input
                    v-model="gallery.translations.hi.location"
                    placeholder="Location"
                  />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Media Event">
              <b-row>
                <b-col cols="2">
                  <b-form-checkbox
                            checked="true"
                            v-model="hasNoDate"
                            name="check-button"
                            class="mb-1 mt-1"
                            switch
                            inline
                          >Disable Date</b-form-checkbox>
                </b-col>
              </b-row>
               <b-row v-if="!hasNoDate">
                   <b-col cols="6">
                <b-calendar
                  v-model="gallery.translations.hi.eventDate.startDate"
                  block
                  locale="en-US"
                />
              </b-col>
                 <b-col cols="6">
                    <b-calendar
                  v-model="gallery.translations.hi.eventDate.endDate"
                  block
                  locale="en-US"
                />
                 </b-col>
                </b-row>
              </b-form-group>
            </b-col> -->
              </b-row>
            </b-form>
        </b-card>
        <b-card title="Media Content">
          <b-tabs>
            <b-tab active title="Video Type">
              <b-form>
                <b-row>
                  <b-col vols="6">
                    <b-form-group label="Banner Video">
                      <b-form-input
                        v-model="gallery.translations.hi.bannerVideo"
                        placeholder="Banner URL"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col vols="6">
                    <b-form-group label="Thumbnail Video">
                      <b-form-input
                        v-model="gallery.translations.hi.thumbnailVideo"
                        placeholder="Thumbnail Url"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-col cols="12">
                      <b-form
                        class="repeater-form"
                        @submit.prevent="repetVideoGallery('hi')"
                      >
                        <b-row
                          v-for="(galleryItem, index) in gallery.translations.hi.videoGallery"
                          :key="index"
                          ref="childGalleryItemRow"
                        >
                          <b-col cols="12">
                            <b-form-group
                              style="background: white"
                              :label="'Gallery Url ' + (index + 1)"
                            >
                              <b-row>
                                <b-col cols="5">
                                  <b-form-input
                                    v-model="gallery.translations.hi.videoGallery[index]"
                                    placeholder="URL"
                                  />
                                </b-col>
                                <b-col lg="2" md="3" class="mb-50">
                                  <b-button
                                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                                    variant="outline-danger"
                                    class="mt-0 mt-md-2"
                                    @click="removeVideoGallery('hi', index)"
                                  >
                                    <feather-icon icon="XIcon" class="mr-25" />
                                    <span>Remove</span>
                                  </b-button>
                                </b-col>
                              </b-row>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col class="mt-1"
                            ><b-button
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="primary"
                              @click="repetVideoGallery('hi')"
                            >
                              <feather-icon icon="PlusIcon" class="mr-25" />
                              <span>{{
                                gallery.translations.hi.videoGallery.length === 0
                                  ? "Add Video URL"
                                  : "Add New URL"
                              }}</span>
                            </b-button></b-col
                          >
                        </b-row>
                      </b-form>
                    </b-col>
                  </b-col>
                  <b-col cols="12">
                    <hr />
                  </b-col>
                </b-row>
              </b-form>
            </b-tab>
            <b-tab title="Image Type">
              <b-form>
                <b-row>
                  <b-col vols="6">
                    <b-form-group label="Banner Image">
                      <b-img
                        class="mb-1"
                        v-bind="mainProps"
                        v-if="gallery.translations.hi.bannerImg"
                        :src="gallery.translations.hi.bannerImg"
                      ></b-img>
                      <b-button
                        v-if="
                          gallery.translations.hi.bannerImg ? gallery.translations.hi.bannerImg.length > 0 : ''
                        "
                        size="sm"
                        @click="gallery.translations.hi.bannerImg = ''"
                        variant="gradient-danger"
                        class="btn-icon ml-2"
                      >
                        <feather-icon icon="TrashIcon" />
                      </b-button>
                      <ComponentMediaSelector
                      :params="{lang: 'hi'}"
                        :isMultiple="false"
                        @mediaSelected="handlebannerImgSelect"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col vols="6">
                    <b-form-group label="Thumbnail Image">
                      <b-img
                        class="mb-1"
                        v-bind="mainProps"
                        v-if="gallery.translations.hi.thumbnailImg"
                        :src="gallery.translations.hi.thumbnailImg"
                      ></b-img>
                      <b-button
                        v-if="
                          gallery.translations.hi.thumbnailImg
                            ? gallery.translations.hi.thumbnailImg.length > 0
                            : ''
                        "
                        size="sm"
                        @click="gallery.translations.hi.thumbnailImg = ''"
                        variant="gradient-danger"
                        class="btn-icon ml-2"
                      >
                        <feather-icon icon="TrashIcon" />
                      </b-button>
                      <ComponentMediaSelector
                      :params="{lang: 'hi'}"
                        :isMultiple="false"
                        @mediaSelected="handlethumbnailImgSelect"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group label="Image Gallery">
                      <div
                        class="mb-1 hotel-gallery"
                        v-if="
                          gallery.translations.hi.imageGallery
                            ? gallery.translations.hi.imageGallery.length > 0
                            : ''
                        "
                      >
                        <div
                          class="gallery-item"
                          :class="index == 0 ? 'mr-1 ml-0' : 'mx-1'"
                          v-for="(media, index) in gallery.translations.hi.imageGallery"
                          :key="media.key"
                        >
                          <div class="actions">
                            <b-button
                              size="sm"
                              @click="removeImageGallery('hi', index)"
                              variant="gradient-danger"
                              class="btn-icon"
                            >
                              <feather-icon icon="TrashIcon" />
                            </b-button>
                          </div>
                          <b-img
                            v-bind="mainProps"
                            rounded
                            :src="media.src"
                          ></b-img>
                        </div>
                      </div>
                      <ComponentMediaSelector
                      :params="{lang: 'hi'}"
                        :selectedMediaFiles="gallery.translations.hi.imageGallery"
                        @mediaSelected="handleImageGallery"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form>
            </b-tab>
          </b-tabs>
        </b-card>
        <b-card title="Button Content">
          <b-form>
            <b-row>
              <b-col cols="6">
                <b-form-group label="Button Title">
                  <b-form-input
                    v-model="gallery.translations.hi.button.buttonText"
                    placeholder="Button Title"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Button Link">
                  <b-form-input
                    v-model="gallery.translations.hi.button.buttonUrl"
                    placeholder="Button Link"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </b-tab>

      <b-tab title="Marathi">
        <b-card title="Add Gallery Item">
            <b-form>
              <b-row>
                <b-col cols="6">
                  <b-form-group label="Title" class="mb-0">
                      <b-form-input
                        v-model="gallery.translations.mr.title"
                        placeholder="Title"
                      />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Media Type">
                      <v-select
                        v-model="gallery.translations.mr.type"
                        placeholder="Type"
                        :options="['image', 'video']"
                      />
                  </b-form-group>
                </b-col>
                <!-- <b-col md="4">
              <b-form-group label="Location">
                  <b-form-input
                    v-model="gallery.translations.mr.location"
                    placeholder="Location"
                  />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Media Event">
              <b-row>
                <b-col cols="2">
                  <b-form-checkbox
                            checked="true"
                            v-model="hasNoDate"
                            name="check-button"
                            class="mb-1 mt-1"
                            switch
                            inline
                          >Disable Date</b-form-checkbox>
                </b-col>
              </b-row>
               <b-row v-if="!hasNoDate">
                   <b-col cols="6">
                <b-calendar
                  v-model="gallery.translations.mr.eventDate.startDate"
                  block
                  locale="en-US"
                />
              </b-col>
                 <b-col cols="6">
                    <b-calendar
                  v-model="gallery.translations.mr.eventDate.endDate"
                  block
                  locale="en-US"
                />
                 </b-col>
                </b-row>
              </b-form-group>
            </b-col> -->
              </b-row>
            </b-form>
        </b-card>
        <b-card title="Media Content">
          <b-tabs>
            <b-tab active title="Video Type">
              <b-form>
                <b-row>
                  <b-col vols="6">
                    <b-form-group label="Banner Video">
                      <b-form-input
                        v-model="gallery.translations.mr.bannerVideo"
                        placeholder="Banner URL"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col vols="6">
                    <b-form-group label="Thumbnail Video">
                      <b-form-input
                        v-model="gallery.translations.mr.thumbnailVideo"
                        placeholder="Thumbnail Url"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-col cols="12">
                      <b-form
                        class="repeater-form"
                        @submit.prevent="repetVideoGallery('mr')"
                      >
                        <b-row
                          v-for="(galleryItem, index) in gallery.translations.mr.videoGallery"
                          :key="index"
                          ref="childGalleryItemRow"
                        >
                          <b-col cols="12">
                            <b-form-group
                              style="background: white"
                              :label="'Gallery Url ' + (index + 1)"
                            >
                              <b-row>
                                <b-col cols="5">
                                  <b-form-input
                                    v-model="gallery.translations.mr.videoGallery[index]"
                                    placeholder="URL"
                                  />
                                </b-col>
                                <b-col lg="2" md="3" class="mb-50">
                                  <b-button
                                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                                    variant="outline-danger"
                                    class="mt-0 mt-md-2"
                                    @click="removeVideoGallery('mr', index)"
                                  >
                                    <feather-icon icon="XIcon" class="mr-25" />
                                    <span>Remove</span>
                                  </b-button>
                                </b-col>
                              </b-row>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col class="mt-1"
                            ><b-button
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="primary"
                              @click="repetVideoGallery('mr')"
                            >
                              <feather-icon icon="PlusIcon" class="mr-25" />
                              <span>{{
                                gallery.translations.mr.videoGallery.length === 0
                                  ? "Add Video URL"
                                  : "Add New URL"
                              }}</span>
                            </b-button></b-col
                          >
                        </b-row>
                      </b-form>
                    </b-col>
                  </b-col>
                  <b-col cols="12">
                    <hr />
                  </b-col>
                </b-row>
              </b-form>
            </b-tab>
            <b-tab title="Image Type">
              <b-form>
                <b-row>
                  <b-col vols="6">
                    <b-form-group label="Banner Image">
                      <b-img
                        class="mb-1"
                        v-bind="mainProps"
                        v-if="gallery.translations.mr.bannerImg"
                        :src="gallery.translations.mr.bannerImg"
                      ></b-img>
                      <b-button
                        v-if="
                          gallery.translations.mr.bannerImg ? gallery.translations.mr.bannerImg.length > 0 : ''
                        "
                        size="sm"
                        @click="gallery.translations.mr.bannerImg = ''"
                        variant="gradient-danger"
                        class="btn-icon ml-2"
                      >
                        <feather-icon icon="TrashIcon" />
                      </b-button>
                      <ComponentMediaSelector
                      :params="{lang: 'mr'}"
                        :isMultiple="false"
                        @mediaSelected="handlebannerImgSelect"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col vols="6">
                    <b-form-group label="Thumbnail Image">
                      <b-img
                        class="mb-1"
                        v-bind="mainProps"
                        v-if="gallery.translations.mr.thumbnailImg"
                        :src="gallery.translations.mr.thumbnailImg"
                      ></b-img>
                      <b-button
                        v-if="
                          gallery.translations.mr.thumbnailImg
                            ? gallery.translations.mr.thumbnailImg.length > 0
                            : ''
                        "
                        size="sm"
                        @click="gallery.translations.mr.thumbnailImg = ''"
                        variant="gradient-danger"
                        class="btn-icon ml-2"
                      >
                        <feather-icon icon="TrashIcon" />
                      </b-button>
                      <ComponentMediaSelector
                      :params="{lang: 'mr'}"
                        :isMultiple="false"
                        @mediaSelected="handlethumbnailImgSelect"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group label="Image Gallery">
                      <div
                        class="mb-1 hotel-gallery"
                        v-if="
                          gallery.translations.mr.imageGallery
                            ? gallery.translations.mr.imageGallery.length > 0
                            : ''
                        "
                      >
                        <div
                          class="gallery-item"
                          :class="index == 0 ? 'mr-1 ml-0' : 'mx-1'"
                          v-for="(media, index) in gallery.translations.mr.imageGallery"
                          :key="media.key"
                        >
                          <div class="actions">
                            <b-button
                              size="sm"
                              @click="removeImageGallery('mr', index)"
                              variant="gradient-danger"
                              class="btn-icon"
                            >
                              <feather-icon icon="TrashIcon" />
                            </b-button>
                          </div>
                          <b-img
                            v-bind="mainProps"
                            rounded
                            :src="media.src"
                          ></b-img>
                        </div>
                      </div>
                      <ComponentMediaSelector
                      :params="{lang: 'mr'}"
                        :selectedMediaFiles="gallery.translations.mr.imageGallery"
                        @mediaSelected="handleImageGallery"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form>
            </b-tab>
          </b-tabs>
        </b-card>
        <b-card title="Button Content">
          <b-form>
            <b-row>
              <b-col cols="6">
                <b-form-group label="Button Title">
                  <b-form-input
                    v-model="gallery.translations.mr.button.buttonText"
                    placeholder="Button Title"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Button Link">
                  <b-form-input
                    v-model="gallery.translations.mr.button.buttonUrl"
                    placeholder="Button Link"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </b-tab>
    </b-tabs>

    <b-card>
      <b-button @click="updateGalleryItem" size="lg" variant="primary" block
        >Update Gallery</b-button
      >
    </b-card>
  </div>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import { quillEditor } from "vue-quill-editor";
import vSelect from "vue-select";
import { required } from "@validations";
import {
  BCard,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BTabs,
  BFormCheckbox,
  BCalendar,
  BTab,
  BFormTextarea,
  BFormInput,
  BImg,
  BButton,
  BAvatar,
  BAlert,
} from "bootstrap-vue";
import axios from "axios";
import { heightFade } from "@core/directives/animations";
import Ripple from "vue-ripple-directive";
import { getUserToken } from "@/auth/utils";
import ComponentMediaSelector from "@/views/Gallery/ComponentMediaSelector";

export default {
  directives: {
    "height-fade": heightFade,
    Ripple,
  },
  components: {
    BAlert,
    BCard,
    BTabs,
    BTab,
    BRow,
    BFormCheckbox,
    BCol,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BCalendar,
    BForm,
    BFormGroup,
    BFormTextarea,
    BFormInput,
    quillEditor,
    ComponentMediaSelector,
    BImg,
    BButton,
    BAvatar,
  },
  data() {
    return {
      required,
      mainProps: {
        width: 100,
        height: 100,
      },
      smallProps: {
        width: 40,
        height: 40,
      },
      snowOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote"],
            ["link"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ align: [] }],
            ["clean"],
          ],
        },
      },
      hasNoDate: false,
      gallery: {
        title: "",
        type: "",
        bannerVideo: "",
        bannerImg: "",
        thumbnailImg: "",
        thumbnailVideo: "",
        videoGallery: [],
        imageGallery: [],
        button: {
          buttonText: "",
          buttonUrl: ""
        },
        translations: {
          hi: {
            title: "",
            bannerVideo: "",
            bannerImg: "",
            thumbnailImg: "",
            thumbnailVideo: "",
            videoGallery: [],
            imageGallery: [],
            button: {
              buttonText: "",
              buttonUrl: ""
            }
          },
           mr: {
            title: "",
            // location: "",
            bannerVideo: "",
            bannerImg: "",
            thumbnailImg: "",
            thumbnailVideo: "",
            videoGallery: [],
            imageGallery: [],
            button: {
              buttonText: "",
              buttonUrl: ""
            }
          }
        }
      },
      galleryID:"",
      isDraftAvailable: false,
    };
  },
  methods: {
    makeToast(variant, title, content) {
      this.$bvToast.toast(content, {
        title: title,
        variant,
        solid: true,
      });
    },
    removeVideoGallery(lang=undefined, index) {
      if(lang) {
      this.gallery.translations[lang].videoGallery.splice(index, 1);
      } else {
      this.gallery.videoGallery.splice(index, 1);
      }
    },
    removeImageGallery(lang=undefined, index) {
      if(lang) {
      this.gallery.translations[lang].imageGallery.splice(index, 1);
      } else {
      this.gallery.imageGallery.splice(index, 1);
      }
    },
    repetVideoGallery(lang=undefined) {
      if(lang) {
      this.gallery.translations[lang].videoGallery.push("");
      } else {
      this.gallery.videoGallery.push("");
      }
    },
    handleBannerVideoSelect(mediaObject) {
      if (mediaObject.status) {
        this.gallery.bannerVideo = mediaObject.media.src;
      }
    },
    handlebannerImgSelect(mediaObject) {
      if (mediaObject.status) {
        if(mediaObject.params.lang) {
        this.gallery.translations[mediaObject.params.lang].bannerImg = mediaObject.media.src;
        } else {
        this.gallery.bannerImg = mediaObject.media.src;
        }
      }
    },
    handleImageGallery(mediaObject) {
      if (mediaObject.status) {
        if(mediaObject.params.lang) {
        this.gallery.translations[mediaObject.params.lang].imageGallery = mediaObject.media;
        } else {
        this.gallery.imageGallery = mediaObject.media;
        }
      }
    },
    handlethumbnailImgSelect(mediaObject) {
      if (mediaObject.status) {
         if(mediaObject.params.lang) {
        this.gallery.translations[mediaObject.params.lang].thumbnailImg = mediaObject.media.src;
        } else {
        this.gallery.thumbnailImg = mediaObject.media.src;
        }
      }
    },
    handleVideoGallery(mediaObject) {
      if (mediaObject.status) {
        this.gallery.videoGallery = mediaObject.media;
      }
    },
    removeBannerVideo() {
      this.gallery.bannerVideo = ""
    },
     updateGalleryItem() {
      this.$refs.galleryItemForm.validate().then((success) => {
        if (success) {
          if(this.hasNoDate) {
            this.gallery.eventDate.startDate = "",
            this.gallery.eventDate.endDate = ""
          }
          axios
            .post(
              `${process.env.VUE_APP_SERVER_URL}/pages/galleryItem/update`,
              {
                id: this.galleryID,
                updates: this.gallery,
              },
              {
                headers: {
                  Authorization: `Bearer ${getUserToken()}`,
                },
              }
            )
            .then((response) => {
              console.log('update', response.data)
              this.makeToast(
                "success",
                "Success",
                `${this.gallery.title} has been created updated!`
              );
            })
            .catch((error) => {
              console.log('err', error.response);
              this.makeToast(
                "danger",
                "Error",
                "Error updating the gallery."
              );
            });
        } else {
          this.makeToast(
            "danger",
            "Error",
            "Please fix the errors to continue."
          );
        }
      });
    },
    handlethumbnailVideoSelect(mediaObject) {
      if (mediaObject.status) {
        this.gallery.thumbnailVideo = mediaObject.media.src;
      }
    },
     getGalleryItem() {
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/pages/galleryItem/get/${this.$route.params.slug}`
        )
        .then((response) => {
          console.log('res', response.data)
          const {
            _id,
             title,
            type,
            bannerVideo,
            bannerImg,
            thumbnailImg,
            thumbnailVideo,
            videoGallery,
            imageGallery,
            button,
            translations
          } = response.data;
          this.galleryID = _id;
          this.gallery = {
            _id,
             title,
            type,
            bannerVideo,
            bannerImg,
            thumbnailImg,
            thumbnailVideo,
            videoGallery,
            imageGallery,
            button: {
              buttonText: button.buttonText ? button.buttonText : "",
              buttonUrl: button.buttonUrl ? button.buttonUrl : "",
            },
            translations: {
              hi: {
            title: translations && translations.hi.title ? translations.hi.title : "",
            bannerVideo:  translations && translations.hi.bannerVideo ? translations.hi.bannerVideo : "",
            bannerImg:  translations && translations.hi.bannerImg ? translations.hi.bannerImg : "",
            thumbnailImg:  translations && translations.hi.thumbnailImg ? translations.hi.thumbnailImg : "",
            thumbnailVideo:  translations && translations.hi.thumbnailVideo ? translations.hi.thumbnailVideo : "",
            videoGallery:  translations && translations.hi.videoGallery ? translations.hi.videoGallery : [],
            imageGallery:  translations && translations.hi.imageGallery ? translations.hi.imageGallery : [],
            button: {
              buttonText: translations && translations.hi.button && translations.hi.button.buttonText ? translations.hi.button.buttonText : "",
              buttonUrl: translations && translations.hi.button && translations.hi.button.buttonUrl ? translations.hi.button.buttonUrl : ""
            }
          },
           mr: {
            title: translations && translations.mr.title ? translations.mr.title : "",
            bannerVideo:  translations && translations.mr.bannerVideo ? translations.mr.bannerVideo : "",
            bannerImg:  translations && translations.mr.bannerImg ? translations.mr.bannerImg : "",
            thumbnailImg:  translations && translations.mr.thumbnailImg ? translations.mr.thumbnailImg : "",
            thumbnailVideo:  translations && translations.mr.thumbnailVideo ? translations.mr.thumbnailVideo : "",
            videoGallery:  translations && translations.mr.videoGallery ? translations.mr.videoGallery : [],
            imageGallery:  translations && translations.mr.imageGallery ? translations.mr.imageGallery : [],
            button: {
              buttonText: translations && translations.mr.button && translations.mr.button.buttonText ? translations.mr.button.buttonText : "",
              buttonUrl: translations && translations.mr.button && translations.mr.button.buttonUrl ? translations.mr.button.buttonUrl : ""
            }
          }
            }
          };
        })
        .catch((error) => {
          console.log('err', error.response);
        });
    },
    saveDraft() {
      localStorage.setItem("gallery-item", JSON.stringify(this.gallery));
      this.makeToast("success", "Success", `Draft Saved!`);
    },
    restoreDraft() {
      const galleryItem = localStorage.getItem("gallery-item");
      console.log('gallery', galleryItem)
      this.gallery = JSON.parse(galleryItem);
    },
    removeDraft() {
      localStorage.removeItem("gallery-item");
      this.isDraftAvailable = false;
    },
  },
  mounted() {
    
    if (localStorage.getItem("gallery-item")) {
      this.isDraftAvailable = true;
    }
    this.getGalleryItem()

  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.hotel-gallery {
  display: flex;
  .gallery-item {
    position: relative;

    .actions {
      position: absolute;
      top: 5px;
      right: 10px;
    }
  }
}
</style>